<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto" md="12" sm="12">
        <v-card>
          <v-card-text v-if="currentSubscription">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('subscriptionsPage.currentSubscription') }}</span>
                <span class="d-block"> {{ getTranslatedPackage(currentSubscription?.subscription_type_id) }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('subscriptionsPage.nextBillingDate') }}</span>
                <span class="d-block" :class="billingDateExpired() ? 'red-alert' : ''">
                  {{ currentSubscription?.next_billing_date_formatted }}
                  <span class="text-uppercase">{{ billingDateExpired() ? $t('subscriptionsPage.pastDue') : '' }}</span>
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('subscriptionsPage.status') }}</span>
                <span class="d-block">{{ currentSubscription?.subscription_status }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('subscriptionsPage.numberOfSeatsTaken') }}</span>
                <span class="d-block">{{ currentSubscription?.takes_seats }}</span>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <span class="d-block font-weight-bold">{{ $t('subscriptionsPage.numberOfSeatsAvailable') }}</span>
                <span class="d-block">{{ currentSubscription?.available_seats }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.transactionsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="subscriptionsHeaders"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="subscriptions"
          :search="searchSubscriptionString"
          :class="!readOnly ? 'cursor-pointer elevation-1' : 'elevation-1'"
          @click:row="clickRow"
          multi-sort>
          <template v-slot:item.status="{ item }">
            {{ getTranslatedType(item.status) }}
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.transactions') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>
              </template>

              <v-text-field
                v-model="searchSubscriptionString"
                append-icon="mdi-magnify"
                hide-details
                :label="$t('tables.search')"
                single-line></v-text-field>

              <v-spacer />

              <router-link
                to="/packages"
                class="black--text text-decoration-none"
                v-if="user.role_id === 2 || user.role_id === 4">
                <v-btn class="mb-2" color="primary" dark>
                  {{ $t('buttons.changePackage') }}
                </v-btn>
              </router-link>

              <v-dialog v-model="subscriptionDialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }" v-if="!readOnly">
                  <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                    {{ $t('buttons.add') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="info title white--text font-weight-bold">
                    {{ $t(formTitle) }}
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="closeEdit"></v-icon>
                  </v-card-title>

                  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                    <v-form lazy-validation @submit.prevent="handleSubmit(saveSubscription)">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider
                                rules="required|max:200"
                                v-slot="{ errors }"
                                name="invoice_number"
                                ref="invoiceNumberRef">
                                <v-text-field
                                  autofocus
                                  type="text"
                                  name="invoice_number"
                                  v-model="editedItem.invoice_number"
                                  clearable
                                  counter="200"
                                  :label="$t('subscriptionsPage.invoiceNumber')"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="status" ref="statusRef">
                                <v-autocomplete
                                  v-model="editedItem.status"
                                  :items="paymentStatuses"
                                  clearable
                                  item-text="name"
                                  item-value="id"
                                  :label="$t('subscriptionsPage.status')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider
                                rules="required|numeric_decimal|max:200"
                                v-slot="{ errors }"
                                name="total"
                                ref="totalRef">
                                <v-text-field
                                  type="text"
                                  name="total"
                                  v-model="editedItem.total"
                                  clearable
                                  counter="200"
                                  :label="$t('subscriptionsPage.total')"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" lg="6" md="6" sm="12">
                              <validation-provider rules="required" v-slot="{ errors }" name="currency">
                                <v-autocomplete
                                  v-model="editedItem.currency"
                                  :items="currencies"
                                  clearable
                                  item-text="code"
                                  item-value="code"
                                  :label="$t('subscriptionsPage.currency')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col lg="6" md="6" sm="12">
                              <v-menu
                                ref="billingAtDate"
                                v-model="billingAtDate"
                                :close-on-content-click="false"
                                :return-value.sync="editedItem.billed_at"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="required" v-slot="{ errors }" name="billed_at"
                                    ><v-text-field
                                      v-model="editedItem.billed_at"
                                      clearable
                                      :label="$t('subscriptionsPage.billedAt')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  v-model="editedItem.billed_at"
                                  :model-config="modelConfig"
                                  mode="date"
                                  :first-day-of-week="firstDay"
                                  @input="$refs.billingAtDate.save(editedItem.billed_at)">
                                </DatePicker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                      </v-card-actions>
                    </v-form>
                  </validation-observer>
                </v-card>
              </v-dialog>

              <v-dialog v-model="subscriptionDialogDelete" max-width="650">
                <v-card>
                  <v-card-title class="info title white--text font-weight-bold">
                    {{ $t('subscriptionsPage.deleteSubscription') }}
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="closeSubscriptionDelete"></v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <p>
                        {{ $t('subscriptionsPage.deleteMessage') }}
                      </p>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :disabled="!enableSave"
                      class="primary"
                      text
                      @click="deleteItemConfirm">
                      {{ $t('buttons.yes') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }" v-if="!readOnly">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn text v-on="{ ...on, ...tooltipOn }">
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('buttons.actions') }}
                  </span>
                </v-tooltip>
              </template>
              <v-list>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="editItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.edit') }}</span>
                </v-tooltip>

                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="deleteItem(item)">
                      <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                      <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                        {{ $t('buttons.delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.delete') }}</span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { subscriptionsHeaders } from '@/mixins/data-table-headers';
import { defaultSubscription } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'SubscriptionItem',
  props: ['organisationId'],
  components: { ButtonSubmit, DatePicker },
  data: () => ({
    user: {
      name: '',
      email: '',
      role_id: null,
    },
    searchSubscriptionString: '',
    subscriptionDialog: false,
    subscriptionDialogDelete: false,
    formTitle: 'subscriptionsPage.newItem',
    editedItem: {},
    editedItemDeleted: {},
    editedItemIndex: null,
    enableSave: true,
    loading: false,
    billingAtDate: null,
    modelConfig: {
      type: 'string',
      mask: 'DD-MM-YYYY',
    },
    readOnly: false,
    subscriptions: [],
    currencies: [{ code: 'EUR' }, { code: 'USD' }, { code: 'GPB' }],
    paymentStatuses: [],
    currentSubscription: null,
  }),
  created() {
    this.editedItem = Object.assign({}, defaultSubscription);
    this.loadUser();
    this.loadAllSubscriptions();
    this.loadAllPaymentStatuses();
    this.loadCurrentSubscription();
  },
  computed: {
    subscriptionsHeaders() {
      return this.readOnly
        ? subscriptionsHeaders(i18n).filter((header) => header.value != 'actions')
        : subscriptionsHeaders(i18n);
    },
  },
  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
      if ([2, 4].includes(this.user.role_id)) {
        this.readOnly = true;
      }
    },
    async loadAllSubscriptions() {
      await this.$store.dispatch('subscriptions/getAllSubscriptions').then((res) => {
        this.subscriptions = res.data.map((item) => {
          return {
            ...item,
            billed_at: formatDate(item.billed_at),
            total: item.total.toFixed(2),
            tax: item.tax.toFixed(2),
          };
        });
      });
    },

    async loadCurrentSubscription() {
      await this.$store.dispatch('subscriptions/getCurrentSubscription').then((res) => {
        this.currentSubscription = { ...res.data, next_billing_date_formatted: formatDate(res.data.next_billing_date) };
      });
    },

    loadAllPaymentStatuses() {
      this.paymentStatuses = [
        { id: 'sent', name: i18n.t('subscriptionsPage.sent') },
        { id: 'completed', name: i18n.t('subscriptionsPage.completed') },
      ];
    },

    editItem(item) {
      this.editedItem = this.subscriptions.indexOf(item);
      this.editedItemIndex = this.subscriptions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = 'subscriptionsPage.editItem';
      this.subscriptionDialog = true;
    },

    deleteItem(item) {
      this.editedItemDeleted = this.subscriptions.indexOf(item);
      this.editedItemDeleted = Object.assign({}, item);
      this.subscriptionDialogDelete = true;
    },

    async saveSubscription() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveSubscription';

      if (this.editedItem.id) {
        Object.assign(this.editedItem, { _method: 'POST' });
        path = 'updateSubscription';
      }

      this.editedItem.organisation_id = this.organisationId;

      await this.$store
        .dispatch('subscriptions/' + path, this.editedItem)
        .then(() => {
          this.loadAllSubscriptions();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeEdit();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.formTitle = 'subscriptionsPage.newItem';
        });
    },

    async deleteItemConfirm() {
      await this.$store
        .dispatch('subscriptions/deleteSubscription', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllSubscriptions();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeSubscriptionDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeSubscriptionDelete();
        });
    },

    closeEdit() {
      this.subscriptionDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultSubscription);
      });
      this.$refs.form.reset();
      this.formTitle = 'subscriptionsPage.newItem';
    },

    closeSubscriptionDelete() {
      this.subscriptionDialogDelete = false;
    },

    clickRow(item) {
      if (!this.readOnly) {
        this.editItem(item);
      }
    },

    getTranslatedType(type) {
      const statuses = {
        sent: 'sent',
        completed: 'completed',
      };

      return statuses[type] ? i18n.t(`subscriptionsPage.${statuses[type]}`) : '';
    },

    getTranslatedPackage(id) {
      const names = {
        15: 'Solo - Yearly',
        5: 'Team 5 - Yearly',
        9: 'Team 10 - Yearly',
        10: 'Team 25 - Yearly',
        11: 'Team 50 - Yearly',
        16: 'Solo - Monthly',
        17: 'Team 5 - Monthly',
        12: 'Team 10 - Monthly',
        13: 'Team 25 - Monthly',
        14: 'Team 50 - Monthly',
      };

      return names[id] ? i18n.t(`packages.${names[id]}`) : '';
    },
    billingDateExpired() {
      if (new Date(this.currentSubscription?.next_billing_date) < new Date()) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    subscriptionDialog(val) {
      val || this.closeEdit();
    },
    '$i18n.locale': {
      handler() {
        this.loadAllPaymentStatuses();
      },
    },
  },
};
</script>

<style>
.red-alert {
  color: red;
  font-weight: bold;
}
</style>
